import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {forkJoin, map, Observable, take} from 'rxjs';
import {ContextConfigService} from './service/context-config.service';
import {NGXLogger} from 'ngx-logger';
import {tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ContextConfigGuard
  implements CanActivate {

  constructor(
    private logger: NGXLogger,
    private coreDataService: ContextConfigService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.logger.trace('CoreDataGuard.canActivate(): ', route, state);

    // INFO: shortcut: if config is already present, simply allow
    if (this.coreDataService.contextConfig) {
      this.logger.trace('CoreDataGuard.canActivate(): data already loaded, skipping...');
      return true;
    }
    this.logger.trace('CoreDataGuard.canActivate(): data not loaded, trigger and wait...');

    // INFO: trigger config load and wait for complete
    return forkJoin([
      // INFO: forkJoin only takes last value, therefore the observable has to be completed
      // since loaded$ ist a replay subject its completes by take(1)
      this.coreDataService.loaded$.pipe(take(1)),
    ]).pipe(
      tap(() => {
        this.logger.trace('CoreDataGuard.canActivate(): data loaded, continue...');
      }),
      // INFO: simply map to true on completion
      map(() => true),
    );
  }
}
