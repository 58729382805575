import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {forkJoin, map, Observable, ReplaySubject, Subject, takeUntil} from 'rxjs';
import {tap} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';


export class BaseConfig {

  constructor(
    public baseUrl: string,
    public environment: string,
    public timeDeviation: number,
  ) {
  }
}


@Injectable()
export class BaseConfigService
  implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private readonly loaded = new ReplaySubject<BaseConfig>();

  private baseConfig_?: BaseConfig;

  constructor(
    private httpClient: HttpClient,
    private logger: NGXLogger,
  ) {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get loaded$() {
    return this.loaded.pipe(
      takeUntil(this.unsubscribe$),
    );
  }

  get baseConfig(): BaseConfig {
    return this.baseConfig_ as BaseConfig;
  }

  loadConfig() {
    return forkJoin([
      this.getBaseConfig(),
    ]);
  }

  private calcTimeDeviation(
    response: HttpResponse<any>,
  ): number {
    const now = new Date();

    const responseDateString = response.headers.get('date');
    if (responseDateString) {
      const responseDate = new Date(responseDateString);
      const diffInMillis = now.getTime() - responseDate.getTime();
      const absDiffInMillis = Math.abs(diffInMillis);

      const timeInfos = {
        now,
        responseDate,
        diffInMillis,
        absDiffInMillis,
      };

      this.logger.info('Client time infos', timeInfos);
      return timeInfos.absDiffInMillis;
    }

    // unknown
    return 0;
  }

  private getBaseConfig(): Observable<BaseConfig> {
    return this.httpClient.get<BaseConfig>('/assets/config/config.json', {observe: 'response'})
      .pipe(
        takeUntil(this.unsubscribe$),
        map(response => {
          const timeDeviation = this.calcTimeDeviation(response);

          const value = response.body as BaseConfig;
          return new BaseConfig(
            value.baseUrl,
            value.environment,
            timeDeviation,
          );
        }),
        tap(value => {
          this.baseConfig_ = value;
          this.loaded.next(this.baseConfig_);
        }),
      );
  }
}
