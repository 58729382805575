import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseConfig, BaseConfigService} from './base-config.service';
import {forkJoin, map, mergeMap, Observable, of, ReplaySubject, Subject, take, takeUntil, tap} from 'rxjs';
import {AppConfig, AppFullKey} from '../../app-view/api/v1/types';
import {NGXLogger} from 'ngx-logger';
import {MatDialog} from '@angular/material/dialog';
import {TimeDeviationDialogComponent} from './time-deviation-dialog/time-deviation-dialog.component';


export interface LoggingConfig {
  level: number,
}

export interface AuthConfig {
  authority: string,
  clientId: string,
  scope: string,
  logLevel: number,
  renewTimeBeforeTokenExpiresInSeconds: number,
}

export interface ShellConfig {
  helpUrl: string,
  selfserviceBaseUrl: string,
  timeDeviation?: {
    minWarn?: number,
    minError?: number,
    minErrorOccurrences?: number,
  },
}

export interface LegalAdditionConfig {
  headline: string,
  text: string,
  links: string[],
}

export interface InfoConfig {
  environment: string,
  version: string,
  commitId: string,
  shortCommitId: string,
  buildTime: string,
}

export interface SentryConfig {
  enabled: boolean,
  debug: boolean,
  dsn: string,
  sampleRate: number,
}

export interface MatomoConfig {
  enabled: boolean,
  trackerUrl: string,
  siteId: string,
}

export interface IConfig {
  base: BaseConfig,
  logging: LoggingConfig,
  auth: AuthConfig,
  shell: ShellConfig
  info: InfoConfig,
  sentry: SentryConfig,
  matomo: MatomoConfig,
}


export class Config
  implements IConfig {

  constructor(
    public base: BaseConfig,
    public logging: LoggingConfig,
    public auth: AuthConfig,
    public shell: ShellConfig,
    public info: InfoConfig,
    public sentry: SentryConfig,
    public matomo: MatomoConfig,
  ) {
  }
}

export class Apps {

  constructor(
    public appConfigs: AppConfig[],
  ) {
  }

  get appConfigMap(): Map<AppFullKey, AppConfig> {
    return new Map(this.appConfigs.map(app => [app.fullKey, app]));
  }

  get appConfigNavigator(): AppConfig[] {
    return this.appConfigs.filter(app => app.navigator);
  }

  get appConfigEmbedded(): AppConfig[] {
    return this.appConfigs.filter(app => app.embedded);
  }

  get appConfigEmbeddedMap(): Map<AppFullKey, AppConfig> {
    return new Map(this.appConfigEmbedded.map(app => [app.fullKey, app]));
  }
}


@Injectable()
export class ConfigService
  implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private readonly loaded = new ReplaySubject<Config>();

  private readonly DEVIATION_COUNT_KEY = 'shell.time-deviation.count';

  private config_?: Config;

  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private logger: NGXLogger,
    private baseConfigService: BaseConfigService,
  ) {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.loaded.complete();
  }

  get loaded$() {
    return this.loaded.pipe(
      takeUntil(this.unsubscribe$),
    );
  }

  get config(): Config {
    return this.config_ as Config;
  }

  loadConfig() {
    return forkJoin([
      this.getConfig(),
    ]);
  }

  private getConfig(): Observable<Config> {
    return this.baseConfigService.loaded$
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        map(value => `${value.baseUrl}/api/v1/config`),
        mergeMap(value => this.httpClient.get<IConfig>(value)),
        mergeMap(value => this.checkTimeDeviation(value)),
        map(value => {
          return new Config(
            this.baseConfigService.baseConfig,
            value.logging,
            value.auth,
            value.shell,
            value.info,
            value.sentry,
            value.matomo,
          );
        }),
        tap(value => {
          this.config_ = value;
          this.loaded.next(this.config_);
        }),
      );
  }

  private checkTimeDeviation(
    config: IConfig,
  ): Observable<IConfig> {
    const timeDeviation = this.baseConfigService.baseConfig?.timeDeviation;
    if (!timeDeviation) {
      // info: time deviation not present
      return of(config);
    }
    const timeDeviationConfig = config.shell?.timeDeviation;
    if (!timeDeviationConfig) {
      // info: time deviation check configuration not present
      return of(config);
    }

    const deviationCount = this.getDeviationCount();

    const minErrorOccurrences = timeDeviationConfig.minErrorOccurrences ?? 5;
    const minError = timeDeviationConfig.minError;
    if (
      deviationCount >= minErrorOccurrences
      && minError && Math.abs(timeDeviation) > Math.abs(minError)
    ) {
      this.setDeviationCount(0);
      this.logger.error('Time deviation is too high: ' + timeDeviation + ' ms.');

      // info: show dialog
      return this.dialog.open(TimeDeviationDialogComponent, {
        width: '450px',
      }).afterClosed()
        .pipe(
          map(() => config),
        );
    }

    // info: a minWarn threshold is required when minError is set to ensure the counter is incremented
    const minWarn = timeDeviationConfig.minWarn ?? timeDeviationConfig.minError;
    if (minWarn && Math.abs(timeDeviation) > Math.abs(minWarn)) {
      this.logger.warn('Time deviation is high: ' + timeDeviation + ' ms, this may cause problems!');
      this.setDeviationCount(deviationCount + 1);

      return of(config);
    }

    return of(config);
  }

  private getDeviationCount(): number {
    try {
      return Number(sessionStorage.getItem(this.DEVIATION_COUNT_KEY)) || 0;
    } catch (e) {
      return Number.MAX_VALUE;
    }
  }

  private setDeviationCount(value: number): number {
    try {
      sessionStorage.setItem(this.DEVIATION_COUNT_KEY, value.toString());
      return value;
    } catch (e) {
      return Number.MAX_VALUE;
    }
  }
}
