import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {firstValueFrom} from 'rxjs';
import {BaseConfigService} from './base-config.service';
import {ConfigService} from './config.service';
import {ContextConfigService} from './context-config.service';
import {TimeDeviationDialogComponent} from './time-deviation-dialog/time-deviation-dialog.component';


@NgModule({
  declarations: [
    TimeDeviationDialogComponent,
  ],
  imports: [
    CommonModule
  ],
  providers: [
    BaseConfigService,
    ConfigService,
    ContextConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: BaseConfigService) => () => firstValueFrom(configService.loadConfig()),
      deps: [BaseConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => firstValueFrom(configService.loadConfig()),
      deps: [ConfigService],
      multi: true,
    },
  ],
})
export class ServiceModule {
}
