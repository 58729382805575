import {Injectable, OnDestroy} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {catchError, NEVER, Observable, Subject, takeUntil} from 'rxjs';
import {AutoLoginAllRoutesGuard, OidcSecurityService} from 'angular-auth-oidc-client';
import {filter, first} from 'rxjs/operators';


/**
 * Da das Portal eine separate Login-Seite hat, die als Lesezeichen gespeichert werden kann, kann es vorkommen,
 * dass Anwender die OIDC/Keycloak-Login-Seite als Lesezeichen speichern.
 *
 * Da darin State/Nonce-Parameter enthalten sind, die nur einmal verwendet werden können,
 * führt das erneute Laden der Seite zu einem Fehler, da der State/Nonce-Parameter nicht mehr gültig ist,
 * bzw. nicht mehr im Session-Storage vorhanden sind.
 *
 * Um dieses Problem zu lösen, wird der Anwender erneut auf die aktuelle Seite weitergeleitet.
 * Hierbei werden die Query-Parameter entfernt, sodass die Seite ohne die Parameter neu geladen wird.
 *
 * Dieser Vorgang wird nur einmal durchgeführt, um eine Endlosschleife zu vermeiden.
 *
 * @see https://gitlab.intern.landdata.de/land-data/shell/shell/-/issues/39#note_88023
 * @see https://github.com/damienbod/angular-auth-oidc-client/issues/1317#issuecomment-1016471616
 */
@Injectable({
  providedIn: 'root'
})
export class MissingStateAutoLoginAllRoutesGuard
  implements OnDestroy, CanActivate, CanActivateChild, CanLoad {

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private autoLoginAllRoutesGuard: AutoLoginAllRoutesGuard,
    private oidcSecurityService: OidcSecurityService,
  ) {
    this.oidcSecurityService.isAuthenticated$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(value => value.isAuthenticated),
        first(),
      )
      .subscribe(() => {
        this.clearErrorCount();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.autoLoginAllRoutesGuard.canActivate(route, state)
      .pipe(
        catchError(err => {
          return this.handleMissingStateError(err);
        }),
      );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.autoLoginAllRoutesGuard.canActivateChild(childRoute, state)
      .pipe(
        catchError(err => {
          return this.handleMissingStateError(err);
        }),
      );
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.autoLoginAllRoutesGuard.canLoad()
      .pipe(
        catchError(err => {
          return this.handleMissingStateError(err);
        }),
      );
  }

  private handleMissingStateError(
    error: any
  ): Observable<any> {
    if (!(error instanceof Error)) {
      // info: re-throw error
      throw error;
    }
    if (error?.message?.indexOf('could not find matching config for state') <= -1) {
      // info: re-throw error
      throw error;
    }

    console.info('MissingStateAutoLoginAllRoutesGuard:handleError: Stale or invalid state found, login page as bookmark?');
    const errorCount = this.readErrorCount();
    if (errorCount >= 1) {
      console.info('MissingStateAutoLoginAllRoutesGuard:handleError: Loop detected, stopping.');
      // info: re-throw error
      throw error;
    }

    this.writeErrorCount(errorCount + 1);

    // info: reload page, without query params (code, state, ...)
    const url = new URL(window.location.href);
    url.search = '';

    console.info('MissingStateAutoLoginAllRoutesGuard:handleError: Attempting reload to fix missing state: ' + url.href);
    window.location.href = url.href;

    // info: do nothing
    return NEVER;
  }

  private readErrorCount(): number {
    try {
      const count = sessionStorage.getItem('invalidStateErrorCount');
      if (!count) {
        return 0;
      }
      return parseInt(count, 10);
    } catch (error) {
      console.error('MissingStateAutoLoginAllRoutesGuard:readErrorCount: Could not read invalidStateErrorCount', error);

      return 0;
    }
  }

  private writeErrorCount(
    errorCount: number,
  ): void {
    try {
      sessionStorage.setItem('invalidStateErrorCount', `${errorCount}`);
    } catch (error) {
      console.error('MissingStateAutoLoginAllRoutesGuard:writeErrorCount: Could not write invalidStateErrorCount', error);
    }
  }

  private clearErrorCount(): void {
    try {
      sessionStorage.removeItem('invalidStateErrorCount');
    } catch (error) {
      console.error('MissingStateAutoLoginAllRoutesGuard:writeErrorCount: Could not clear invalidStateErrorCount', error);
    }
  }
}
