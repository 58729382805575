import {Injectable, OnDestroy} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthInterceptor as OidcAuthInterceptor} from 'angular-auth-oidc-client';
import {NGXLogger} from 'ngx-logger';
import {Observable, Subject, takeUntil} from 'rxjs';
import {Config, ConfigService} from '../config/service/config.service';
import {EmbeddedService} from '../embedded/embedded.service';


@Injectable()
export class AuthInterceptor
  implements HttpInterceptor, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private config?: Config;

  constructor(
    private readonly logger: NGXLogger,
    private readonly configService: ConfigService,
    private readonly embeddedService: EmbeddedService,
    private readonly oidcAuthInterceptor: OidcAuthInterceptor,
  ) {
    this.configService.loaded$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(value => {
        this.config = value;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {

    // INFO: check for intercepting
    const matchesSecureRoots = this.matchesSecureRoots(request.url);
    if (!matchesSecureRoots) {
      return next.handle(request);
    }

    // TODO: Intelligenter lösen, ggf. Ansatz über Guard im Embedded-Modus der auf Adnova-Adapter / Access Token wartet
    if (this.embeddedService.embedded || window.document.location.pathname.startsWith('/embedded')) {
      // INFO: intercepting running in portal mode
      const token = this.embeddedService.getAccessToken();
      if (!token) {
        this.logger.debug(`Wanted to add token to ${request.url} but found no token: '${token}'`);
        return next.handle(request);
      }

      this.logger.trace(`Using AuthInterceptor in embedded mode`);
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
      return next.handle(request);
    }

    // INFO: intercepting running in standalone mode
    this.logger.trace(`Using AuthInterceptor in standalone mode`);
    return this.oidcAuthInterceptor.intercept(request, next);
  }

  private matchesSecureRoots(
    route: string,
  ): boolean {
    const baseUrl = this.config?.base?.baseUrl;
    if (!baseUrl) {
      this.logger.debug(`Skipping, 'baseUrl' missing`);
      return false;
    }
    const selfserviceBaseUrl = this.config?.shell?.selfserviceBaseUrl;
    if (!selfserviceBaseUrl) {
      this.logger.debug(`Skipping, 'selfserviceBaseUrl' missing`);
      return false;
    }

    const secureRoutes = [baseUrl, selfserviceBaseUrl];
    for (const configuredRoute of secureRoutes) {
      if (route.startsWith(configuredRoute)) {
        this.logger.trace(`'${route}' matches configured route '${configuredRoute}'`);
        return true;
      }
    }
    this.logger.trace(`Did not find any configured route for route ${route}`);
    return false;
  }
}
