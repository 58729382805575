<h1>Hinweis</h1>
<div>
  <p>
    Korrigiere Uhrzeit/Datum über die Einstellungen deines Betriebssystems.<br>
    Setze dich bei Fragen bitte mit dem <a href="mailto:service@just-farming.de">Just Farming Benutzerservice</a> in Verbindung.
  </p>
</div>

<div>
  <button (click)="close()">Schließen</button>
</div>
