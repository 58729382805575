import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-eula-popup',
  templateUrl: './eula-popup.component.html',
  styleUrls: ['./eula-popup.component.scss']
})
export class EulaPopupComponent {

  constructor(
    private dialogRef: MatDialogRef<EulaPopupComponent>,
  ) {
  }

  approved(
    approved: boolean,
  ): void {
    this.dialogRef.close(approved);
  }
}
