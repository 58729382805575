import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, filter, map, Observable, Subject, takeUntil} from 'rxjs';
import {
  AccessTokenResult,
  AccessTokenResultError,
  ContextInfo,
  ContextInfoProperty,
  EmitAccessTokenRequest,
  EmitCloseRequest,
  EmitOutgoingIntentRequest,
  IngoingIntent,
  OutgoingIntentResult,
  PropertyKey
} from '../app-view/api/v1/types';
import {NGXLogger} from 'ngx-logger';
import {NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';


interface AdnovaDispatcher {
  dispatchIntent: (ingoingIntent: IngoingIntent) => void,
  dispatchContextInfo: (contextInfo: ContextInfo) => void,
}

interface AdnovaAdapter {
  requestAccessToken(
    accessTokenRequest: EmitAccessTokenRequest
  ): AccessTokenResult,

  handleOutgoingIntentRequest(
    outgoingIntentRequest: EmitOutgoingIntentRequest
  ): OutgoingIntentResult,

  requestClose(): void,

  getEmbedding(): string;

  connected(): void
}


@Injectable({
  providedIn: 'root'
})
export class EmbeddedService
  implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private readonly embedded_ = new BehaviorSubject<boolean>(false);

  private readonly _embedding$ = new BehaviorSubject<string>('');

  private readonly _contextInfo$ = new BehaviorSubject<Map<PropertyKey, ContextInfoProperty>>(new Map());

  private adnovaDispatcher?: AdnovaDispatcher;

  readonly ingoingIntent$ = new Subject<IngoingIntent>();

  readonly accessTokenListeners = new Array<(accessToken: string) => void>();

  constructor(
    private logger: NGXLogger,
    private router: Router,
  ) {
    // INFO: set embedded
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => {
          if (event instanceof NavigationEnd) {
            return true;
          }
          if (event instanceof NavigationStart) {
            return true;
          }
          return false;
        }),
        map(event => event as RouterEvent),
        map(event => event.url),
        map(url => url.startsWith('/embedded')),
        distinctUntilChanged(),
      )
      .subscribe(isEmbedded => {
        this.updateEmbedding(isEmbedded);

        this.embedded_.next(isEmbedded);
        this.toggleDispatchAdapter(isEmbedded);

        this.logger.trace('Changed embedded, isEmbedded=' + isEmbedded);
      });

    // INFO: init toggle to false
    this.toggleDispatchAdapter(false);
  }

  ngOnDestroy(): void {
    // INFO: complete all subscriptions
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    // INFO: complete ingoing intents
    this.ingoingIntent$.complete();
    // INFO: remove dispatch adapter on destroy
    this.removeDispatchAdapter();
  }

  get contextInfo(): Map<PropertyKey, ContextInfoProperty> {
    return new Map(this._contextInfo$.value);
  }

  get contextInfo$(): Observable<Map<PropertyKey, ContextInfoProperty>> {
    return this._contextInfo$.asObservable();
  }

  get embedded$(): Observable<boolean> {
    return this.embedded_.asObservable();
  }

  get embedded(): boolean {
    return this.embedded_.value;
  }

  get embedding$(): Observable<string> {
    return this._embedding$.asObservable();
  }

  get embedding(): string {
    return this._embedding$.value;
  }

  public getAccessToken(): string {
    const adnovaAdapter = this.getAdnovaAdapter();
    if (adnovaAdapter) {
      try {
        const accessTokenRequest: EmitAccessTokenRequest = {
          appFqn: 'system:embedded',
          accessTokenRequest: {},
          resultCallback: () => {
            // no-op
          }
        };

        const result = adnovaAdapter.requestAccessToken(accessTokenRequest);
        if (result && result.success && result.accessToken) {
          for (const accessTokenListenersFunction of this.accessTokenListeners) {
            accessTokenListenersFunction(result.accessToken);
          }
          return result.accessToken;
        }
      } catch (error) {
        this.logger.warn('Embedded: Error during request access request', error);
      }
    }
    return '';
  }

  public addAccessTokenListener(
    handler: (accessToken: string) => void,
  ): void {
    this.accessTokenListeners.push(handler);
  }

  private getAdnovaAdapter(): AdnovaAdapter | undefined {
    try {
      const adnovaAdapter = (<any>window).adnovaAdapter;
      if (!adnovaAdapter) {
        this.logger.warn('Could not find adnova adapter');
      }

      return adnovaAdapter;
    } catch (e) {
      this.logger.warn('Could not find adnova adapter', e);

      return undefined;
    }
  }

  private updateEmbedding(
    embedded: boolean,
  ): void {
    if (embedded) {
      const adapter = this.getAdnovaAdapter();
      if (adapter) {
        try {
          const embedding = adapter.getEmbedding();
          if (embedding) {
            this._embedding$.next(embedding);
            return;
          }
        } catch (e) {
          this.logger.warn('Could not read embedding', e);
        }
      }
    }

    this._embedding$.next('');
  }

  private toggleDispatchAdapter(
    isEmbedded: boolean
  ): void {
    if (isEmbedded) {
      this.registerDispatchAdapter();
    } else {
      this.removeDispatchAdapter();
    }
  }

  private removeDispatchAdapter(): void {
    if (this.adnovaDispatcher) {
      // INFO: 'invalidate' current, prevent old references usage
      this.adnovaDispatcher.dispatchIntent = () => {
      };
      (<any>window).adnovaDispatcher = undefined;
      this.adnovaDispatcher = undefined;

      this.logger.trace('Removed \'window.adnovaDispatcher\'');
    }
  }

  private registerDispatchAdapter(): void {
    this.removeDispatchAdapter();
    this.adnovaDispatcher = {
      dispatchIntent: ingoingIntent => this.dispatchIntentEmbedded(ingoingIntent),
      dispatchContextInfo: contextInfo => this.dispatchContextInfoEmbedded(contextInfo),
    };
    (<any>window).adnovaDispatcher = this.adnovaDispatcher;

    this.getAdnovaAdapter()?.connected();

    this.logger.trace('Added \'window.adnovaDispatcher\'');
  }

  private dispatchIntentEmbedded(
    ingoingIntent: IngoingIntent,
  ) {
    this.logger.trace('Embedded: Dispatching ingoing intent', ingoingIntent);
    if (!this.embedded) {
      this.logger.warn('Embedded: Try handling ingoing intent, but not in embedded mode, ignoring');
      return;
    }
    this.ingoingIntent$.next(ingoingIntent);
  }

  private dispatchContextInfoEmbedded(
    contextInfo: ContextInfo,
  ): void {
    this.logger.trace('Embedded: Dispatching context info', contextInfo);
    if (!this.embedded) {
      this.logger.warn('Embedded: Try handling context info, but not in embedded mode, ignoring');
      return;
    }
    if (!contextInfo.properties) {
      this.logger.warn('Embedded: Try handling context info, properties missing, ignoring');
      return;
    }

    const properties = new Map<PropertyKey, ContextInfoProperty>(Object.entries(contextInfo.properties));
    this._contextInfo$.next(properties);
  }

  requestAccessToken(
    accessTokenRequest: EmitAccessTokenRequest,
  ): void {
    if (this.embedded) {
      this.requestAccessTokenEmbedded(accessTokenRequest);
    } else {
      this.logger.debug('could not request access token, not in embedded mode');
      accessTokenRequest.resultCallback({
        error: AccessTokenResultError.UNKNOWN,
        message: 'unknown',
        success: false,
      });
    }
  }

  private requestAccessTokenEmbedded(
    accessTokenRequest: EmitAccessTokenRequest,
  ): void {
    const adnovaAdapter = this.getAdnovaAdapter();
    if (adnovaAdapter) {
      try {
        const result = adnovaAdapter.requestAccessToken(accessTokenRequest);
        accessTokenRequest.resultCallback(result);

        // INFO: Callback um auf neue Access Token zu reagieren
        if (result && result.success && result.accessToken) {
          for (const accessTokenListenersFunction of this.accessTokenListeners) {
            accessTokenListenersFunction(result.accessToken);
          }
        }

        this.logger.trace('Embedded: Access token request succeeded');
        return;
      } catch (error) {
        this.logger.warn('Embedded: Error during request access request', error);
      }
    }

    accessTokenRequest.resultCallback({
      success: false,
      error: AccessTokenResultError.UNKNOWN,
    });
    this.logger.warn('Standalone: Access token request failed');
  }

  handleOutgoingIntentRequestEmbedded(
    outgoingIntentRequest: EmitOutgoingIntentRequest,
  ): void {
    this.logger.debug('Handling outgoing intent in embedded mode', outgoingIntentRequest);

    if (this.embedded) {
      // TODO resultCallback
      // TODO logging
      return;
    }

    // TODO: Prüfen ob Intent für Embedded Verfügbar ist
    // TODO: Intent gegen Intent-Json Schema validieren
    // TODO: Intent an Embedded-Parent senden
    // TODO: Senden des Intents bestätigen

    const adnovaAdapter = this.getAdnovaAdapter();
    if (adnovaAdapter) {
      try {
        const result = adnovaAdapter.handleOutgoingIntentRequest(outgoingIntentRequest);
        outgoingIntentRequest.resultCallback(result);

        return;
      } catch (e) {
        this.logger.warn('Could not handle outgoing intent request', e);
      }
    }
    outgoingIntentRequest.resultCallback({
      success: false,
    });
  }

  requestCloseEmbedded(
    emitCloseRequest: EmitCloseRequest,
  ): void {
    this.logger.trace('Embedded: Handling close request');

    if (!this.embedded) {
      // TODO Logging
      return;
    }

    const adnovaAdapter = this.getAdnovaAdapter();
    if (!adnovaAdapter) {
      this.logger.warn('Embedded: Could not handle close request, adnova adapter missing');
      return;
    }
    adnovaAdapter.requestClose();
  }
}
