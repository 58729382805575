import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-time-deviation-dialog',
  templateUrl: './time-deviation-dialog.component.html',
  styleUrls: ['./time-deviation-dialog.component.scss']
})
export class TimeDeviationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<TimeDeviationDialogComponent>,
  ) {
  }

  close(): void {
    this.dialogRef.close();
  }
}
