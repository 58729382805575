<h1>Datenschutzerklärung</h1>
<app-privacy-policy-content></app-privacy-policy-content>

<div class="approval" [hidden]="!showApproveButton">
  <mat-checkbox
    matRipple
    [matRippleCentered]="true"
    #checkbox
  >
    Datenschutzerklärung gelesen und akzeptiert
  </mat-checkbox>
  <br>
  <button
    mat-flat-button
    color="primary"
    (click)="approve()"
  >
    Datenschutzerklärung akzeptieren
  </button>
</div>

