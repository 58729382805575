import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ServiceModule} from './service/service.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ServiceModule,
  ],
  providers: [
  ],
})
export class ConfigModule {
}
