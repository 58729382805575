import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ContextConfigGuard} from './config/context-config-guard.service';
import {EulaGuard} from './eula/eula.guard';
import {MissingStateAutoLoginAllRoutesGuard} from './auth/missing-state-auto-login-all-routes-guard.service';


// TODO Add auth route guard
// https://nice-hill-002425310.azurestaticapps.net/docs/documentation/auto-login
// https://nice-hill-002425310.azurestaticapps.net/docs/documentation/guards
const routes: Routes = [
  {
    path: '',
    redirectTo: 'standalone',
    pathMatch: 'full'
  },
  {
    path: 'standalone',
    loadChildren: () => import('./standalone/standalone.module').then(m => m.StandaloneModule),
    canActivate: [MissingStateAutoLoginAllRoutesGuard, ContextConfigGuard, EulaGuard],
  },
  {
    path: 'embedded',
    loadChildren: () => import('./embedded/embedded.module').then(m => m.EmbeddedModule),
    canActivate: [ContextConfigGuard],
  },
  {
    path: '**',
    redirectTo: 'standalone'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
