import {Component, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Config, ConfigService} from './config/service/config.service';
import {merge, Subject, takeUntil} from 'rxjs';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {filter, first} from 'rxjs/operators';
import {ContextConfigService} from './config/service/context-config.service';
import {EmbeddedService} from './embedded/embedded.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
  implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private titleService: Title,
    private configService: ConfigService,
    private contextConfigService: ContextConfigService,
    private oidcSecurityService: OidcSecurityService,
    private embeddedService: EmbeddedService,
  ) {
    // info: update title
    this.configService.loaded$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(value => {
        this.updateTitle(value);
      });

    // info: observe auth (standalone mode)
    const authenticated = this.oidcSecurityService.isAuthenticated$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(value => value.isAuthenticated),
        first(),
      );
    // info: observe embedded mode, external auth
    const embedded = this.embeddedService.embedded$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(value => value),
        first(),
      );
    // info join conditions, first wins
    const shouldLoad = merge(authenticated, embedded)
      .pipe(
        takeUntil(this.unsubscribe$),
        first(),
      );
    // info: trigger context data load when auth is present
    this.contextConfigService.loadConfig(shouldLoad)
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateTitle(
    config: Config,
  ): void {
    const environment = config?.base?.environment;
    if (!environment || environment === 'production' || environment.length === 0) {
      return;
    }
    const title = this.titleService.getTitle();
    const newTitle = `${title} --${environment.toUpperCase()}--`;

    this.titleService.setTitle(newTitle);
  }
}
