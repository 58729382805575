import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NGXLogger} from 'ngx-logger';
import {forkJoin, map, Observable, take} from 'rxjs';
import {ContextConfig, ContextConfigService} from '../config/service/context-config.service';
import {EulaPopupComponent} from './eula-popup/eula-popup.component';
import {ConfigService} from '../config/service/config.service';


interface EulaApproval {
  approved: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class EulaGuard
  implements CanActivate {

  constructor(
    private logger: NGXLogger,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private contextConfigService: ContextConfigService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
  }

  private showEulaApproval(
    contextConfig: ContextConfig,
    state: RouterStateSnapshot
  ) {
    // TODO: disable animation with Angular 14+
    const dialogRef = this.dialog.open(EulaPopupComponent, {
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          const baseUrl = this.configService.config.base.baseUrl;

          const url = `${baseUrl}/api/v1/eula/approval`;
          const data: EulaApproval = {
            approved: true,
          };
          this.httpClient.post(url, data)
            .subscribe({
              next: value => {
                this.logger.info('EULA approval successful', value);

                // info: save locally and navigate desired url
                contextConfig.eula.approved = true;
                this.router.navigate([state.url]);
              },
              error: err => {
                this.logger.warn('EULA approval failed', err);

                // info: retry approval
                this.snackBar.open('Es ist ein Fehler aufgetreten, bitte versuche es erneut', undefined, {
                    duration: 5000,
                    panelClass: 'error'
                  },
                );
                this.showEulaApproval(contextConfig, state);
              },
            });
        } else {
          this.logger.info('EULA approval declined');

          // info: retry approval
          this.snackBar.open('Du musst zustimmen um das Just Farming Portal nutzen zu können', undefined,
            {
              duration: 5000,
              panelClass: 'error'
            },
          );
          this.showEulaApproval(contextConfig, state);
        }
      });
  }

  private checkEulaApproval(
    contextConfig: ContextConfig,
    state: RouterStateSnapshot,
  ): boolean {
    if (contextConfig.eula.approved) {
      // info: no approval needed, continue
      return true;
    }
    // info: show
    this.logger.trace('EulaGuard: eula approval needed');
    this.showEulaApproval(contextConfig, state);
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const contextConfig = this.contextConfigService.contextConfig;

    // INFO: shortcut: if config is already present, simply allow
    if (contextConfig) {
      return this.checkEulaApproval(contextConfig, state);
    }
    this.logger.trace('EulaGuard: context config missing, waiting');

    // INFO: trigger config load and wait for complete
    return forkJoin([
      // INFO: forkJoin only takes last value, therefore the observable has to be completed
      // since loaded$ ist a replay subject its completes by take(1)
      this.contextConfigService.loaded$.pipe(take(1)),
    ]).pipe(
      // INFO: simply map to true on completion
      map(value => {
        const contextConfig = value[0];
        return this.checkEulaApproval(contextConfig, state);
      }),
    );
  }
}
