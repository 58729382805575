import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatRipple} from '@angular/material/core';


@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent {

  @ViewChild(MatCheckbox)
  checkbox!: MatCheckbox;

  @ViewChild(MatRipple)
  ripple!: MatRipple;

  @Input()
  showApproveButton = false;

  @Output()
  approved = new EventEmitter<boolean>();

  constructor() {
  }

  approve() {
    if (this.checkbox.checked) {
      this.approved.emit(true);
    } else {
      this.ripple.launch({});
    }
  }
}
