<p>
  Sehr geehrte Damen und Herren,
</p>
<p>
  hiermit informieren wir Sie über die Verarbeitung Ihrer personenbezogenen Daten durch uns und die Ihnen nach den
  datenschutzrechtlichen Regelungen zustehenden Ansprüche und Rechte.
</p>
<h3>
  1. Wer ist für die Datenverarbeitung verantwortlich und an wen kann ich mich wenden?
</h3>
<p>
  Verantwortlicher im datenschutzrechtlichen Sinne ist:
</p>
<p>
  Just Farming GmbH<br>
  Speicherstr. 33<br>
  44147 Dortmund<br>
  Deutschland
</p>
<p>
  Internet: <a href="https://www.just-farming.de">www.just-farming.de</a><br>
  E-Mail: <a href="mailto:info@just-farming.de">info@just-farming.de</a><br>
  Telefon: <a href="tel:+4923122613790">+49 231 2261379-0</a></p>
<p>
  Sie erreichen unseren Datenschutzbeauftragten unter:
</p>
<p>
  heyData GmbH<br>
  Schützenstraße 5<br>
  10117 Berlin<br>
  Deutschland
</p>
<p>
  Internet: <a href="https://www.heydata.eu">www.heydata.eu</a><br>
  E-Mail: <a href="mailto:datenschutz@heydata.eu">datenschutz@heydata.eu</a><br>
  Telefon: <a href="tel:+498941325320">+49 89 41325320</a></p>
<h3>
  2. Welche Quellen und Daten nutzen wir?
</h3>
<p>
  Wir verarbeiten personenbezogene Daten, die wir im Rahmen unserer Geschäftsbeziehung von Ihnen erhalten.
</p>
<p>
  Relevante personenbezogene Daten sind Personalien (z. B. Name, berufliche Adresse und andere berufliche
  Kontaktdaten), Ihr Arbeitgeber sowie Daten über Ihre Nutzung von unseren angebotenen Telemedien (z. B. Zeitpunkt
  des Aufrufs unserer Webseiten, Apps oder Newsletter, angeklickte Seiten von uns bzw.
  Einträge) sowie andere mit den genannten Kategorien vergleichbare Daten sein.
</p>
<h3>
  3. Wofür verarbeiten wir Ihre Daten (Zweck der Verarbeitung) und auf welcher Rechtsgrundlage?
</h3>
<p>
  a) Zur Erfüllung von vertraglichen Pflichten (Art. 6 Abs. 1 Buchst. b DS-GVO) Die Verarbeitung personenbezogener
  Daten (Art. 4 Nr. 2 DSGVO) erfolgt zur Vorbereitung und Durchführung Ihrer Bestellungen (Kunden) bzw. unserer
  Einkäufe (Zulieferer).
</p>
<p>
  b) Im Rahmen der Interessenabwägung (Art. 6 Abs. 1 Buchst. f DSGVO)
</p>
<p>
  Soweit erforderlich, verarbeiten wir Ihre Daten über die eigentliche Erfüllung des Vertrages hinaus zur Wahrung
  unserer berechtigten Interessen.
</p>
<p>
  Beispiele:
</p>
<p>
  - Prüfung und Optimierung von Verfahren zur Bedarfsanalyse und direkter Kundenansprache - Werbung oder Markt- und
  Meinungsforschung, soweit Sie der Nutzung Ihrer Daten nicht widersprochen haben
</p>
<p>
  - Geltendmachung rechtlicher Ansprüche und Verteidigung bei rechtlichen Streitigkeiten
</p>
<p>
  c) Aufgrund Ihrer Einwilligung (Art. 6 Abs. 1 Buchst. a DSGVO)
</p>
<p>
  Soweit Sie uns eine Einwilligung zur Verarbeitung von personenbezogenen Daten für bestimmte Zwecke erteilt haben,
  ist die Rechtmäßigkeit dieser Verarbeitung auf Basis Ihrer Einwilligung gegeben. Eine erteilte Einwilligung kann
  jederzeit widerrufen werden. Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt. Verarbeitungen,
  die vor dem Widerruf erfolgt sind, sind davon nicht betroffen.
</p>
<p>
  d) Aufgrund gesetzlicher Vorgaben (Art. 6 Abs. 1 Buchst. c DSGVO) oder im öffentlichen Interesse (Art. 6 Abs. 1
  Buchst. e DSGVO)
</p>
<p>
  Ausnahmsweise kann es vorkommen, dass wir auf Grund gesetzlicher Vorgaben bzw. im öffentlichen Interesse Daten
  verarbeiten müssen (bspw. auf Grund behördlicher Auskunftsersuchen).
</p>
<h3>
  4. Wer bekommt meine Daten?
</h3>
<p>
  Innerhalb unseres Hauses erhalten diejenigen Personen Zugriff auf Ihre Daten, die diese zur Erfüllung unserer
  vertraglichen und gesetzlichen Pflichten brauchen.
</p>
<p>
  Auch von uns eingesetzte Auftragsverarbeiter (Art. 28 DS-GVO) können zu diesen genannten Zwecken Daten erhalten.
  Dies sind Unternehmen in den Kategorien IT-Dienstleistungen, Logistik,
  Druckdienstleistungen, Telekommunikation, Inkasso, Beratung und Consulting sowie Vertrieb und Marketing.
</p>
<h3>
  5. Wie lange werden meine Daten gespeichert?
</h3>
<p>
  Soweit erforderlich, verarbeiten und speichern wir Ihre personenbezogenen Daten für die Dauer unserer
  Geschäftsbeziehung, was beispielsweise auch die Anbahnung und die Abwicklung eines Vertrages umfasst.
</p>
<p>
  Darüber hinaus unterliegen wir verschiedenen Aufbewahrungs- und Dokumentationspflichten, die sich unter anderem
  aus dem Handelsgesetzbuch (HGB) und der Abgabenordnung (AO) ergeben. Die dort vorgegebenen Fristen zur
  Aufbewahrung bzw. Dokumentation betragen bis zu zehn Jahre.
</p>
<p>
  Schließlich beurteilt sich die Speicherdauer auch nach den gesetzlichen Verjährungsfristen, die zum Beispiel nach
  den §§ 195 ff. des Bürgerlichen Gesetzbuches (BGB) in der Regel 3 Jahre, in gewissen Fällen aber auch bis zu
  dreißig Jahre betragen können.
</p>
<h3>
  6. Werden Daten in ein Drittland oder an eine internationale Organisation übermittelt?
</h3>
<p>
  Eine Datenübermittlung in Drittstaaten (Staaten außerhalb des Europäischen Wirtschaftsraums – EWR) findet nur
  statt, soweit dies zur Ausführung Ihrer Aufträge erforderlich ist.
</p>
<h3>
  7. Welche Datenschutzrechte habe ich?
</h3>
<p>
  Jede betroffene Person hat das Recht auf Auskunft nach Art. 15 DS-GVO, das Recht auf Berichtigung nach Art. 16
  DS-GVO, das Recht auf Löschung nach Art. 17 DS-GVO, das Recht auf Einschränkung der Verarbeitung nach Art. 18
  DS-GVO sowie das Recht auf
</p>
<p>
  Datenübertragbarkeit aus Art. 20 DS-GVO. Beim Auskunftsrecht und beim Löschungsrecht gelten die Einschränkungen
  nach §§ 34 und 35 BDSG.
</p>
<p>
  Darüber hinaus besteht ein Beschwerderecht bei der zuständigen Datenschutzaufsichtsbehörde (Art. 77 DS-GVO i.V.m.
  § 19 BDSG):
</p>
<p>
  Die Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen
  Kavalleriestr. 2-4<br>
  40213 Düsseldorf<br>
  <br>
  E-Mail: <a href="mailto:poststelle@ldi.nrw.de">poststelle@ldi.nrw.de</a><br>
  Telefon: <a href="tel:+49211384240">+49 211 38424 - 0</a><br>
  Fax: +49 211 38424 - 999
</p>
<h3>
  8. Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO
</h3>
<p>
  a) Einzelfallbezogenes Widerspruchsrecht
</p>
<p>
  Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
  Verarbeitung Sie betreffen- der personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstabe e der
  DSGVO (Datenverarbeitung im öffentlichen Interesse) und Artikel 6 Absatz 1 Buchstabe f der DS-GVO
  (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch
  für ein auf diese Bestimmung gestütztes Profiling.
</p>
<p>
  Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
  können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
  Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
  Rechtsansprüchen.
</p>
<p>
  b) Widerspruchsrecht gegen eine Verarbeitung von Daten für Zwecke der Direktwerbung In Einzelfällen verarbeiten
  wir Ihre personenbezogenen Daten, um Direktwerbung zu betreiben. Sie haben das Recht, jederzeit Widerspruch
  gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies
  gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
</p>
<p>
  Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden wir Ihre personenbezogenen Daten nicht
  mehr für diese Zwecke verarbeiten.
</p>
