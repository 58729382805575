import {Injectable, OnDestroy} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, debounceTime, Observable, Subject, takeUntil} from 'rxjs';
import {
  ContextInfoProperty,
  PropertyKey,
  WellKnownJustFarmingProperties,
  WellKnownShellProperties
} from '../app-view/api/v1/types';
import {EmbeddedService} from '../embedded/embedded.service';
import {MatomoTracker} from '@ngx-matomo/tracker';


export const CONSENT_ANALYTICS_KEY = 'shell.consent.analytics:2023-03-08';

export const CONSENT_ERROR_REPORTING_KEY = 'shell.consent.error-reporting:2023-03-08';


@Injectable({
  providedIn: 'root'
})
export class ContextInfoService
  implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private readonly _sharedContextInfo$ = new BehaviorSubject<Map<PropertyKey, ContextInfoProperty>>(new Map());

  private savedConsentAnalytics = false;

  private savedConsentErrorReporting = false;

  private _consentAnalytics$ = new BehaviorSubject(false);

  private _consentErrorReporting$ = new BehaviorSubject(false);

  constructor(
    private readonly logger: NGXLogger,
    private readonly embeddedService: EmbeddedService,
    private readonly tracker: MatomoTracker,
  ) {
    this.initSavedConsents();

    this.consentAnalytics$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(value => {
        if (value) {
          this.tracker.setConsentGiven()
        } else {
          this.tracker.forgetConsentGiven();
        }
        this.setContextProperty(WellKnownJustFarmingProperties.ANALYTICS, value);
      });

    this.consentErrorReporting$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(value => {
        this.setContextProperty(WellKnownJustFarmingProperties.ERROR_REPORTING, value);
      });

    this.embeddedService.embedded$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(embedded => this.refreshContextProperties(embedded));
  }

  // TODO: Add setters saved consents

  private initSavedConsents() {
    // analytics
    try {
      const analytics = localStorage.getItem(CONSENT_ANALYTICS_KEY);
      if (analytics === 'true') {
        this.savedConsentAnalytics = true;
      }
    } catch (e) {
      this.logger.info('Could not read analytics consent', e);
    }
    // error reporting
    try {
      const errorReporting = localStorage.getItem(CONSENT_ERROR_REPORTING_KEY);
      if (errorReporting === 'true') {
        this.savedConsentErrorReporting = true;
      }
    } catch (e) {
      this.logger.info('Could not read error reporting consent', e);
    }

    if (!this.embeddedService.embedded) {
      this.consentAnalytics = this.savedConsentAnalytics;
      this.consentErrorReporting = this.savedConsentErrorReporting;
    }
  }

  get consentAnalytics() {
    return this._consentAnalytics$.value;
  }

  get consentAnalytics$() {
    return this._consentAnalytics$.asObservable();
  }

  set consentAnalytics(
    value: boolean
  ) {
    this._consentAnalytics$.next(value);
  }

  get consentErrorReporting() {
    return this._consentErrorReporting$.value;
  }

  get consentErrorReporting$() {
    return this._consentErrorReporting$.asObservable();
  }

  set consentErrorReporting(
    value: boolean
  ) {
    this._consentErrorReporting$.next(value);
  }

  saveConsent(analytics: boolean, errorReporting: boolean): void {
    this.savedConsentAnalytics = analytics;
    this.savedConsentErrorReporting = errorReporting;
    this.refreshContextProperties(this.embeddedService.embedded);

    try {
      localStorage.setItem(CONSENT_ANALYTICS_KEY, String(this.savedConsentAnalytics));
      localStorage.setItem(CONSENT_ERROR_REPORTING_KEY, String(this.savedConsentErrorReporting));
    } catch (e) {
      this.logger.warn('Could not save analytics consent', e);
    }
  }

  isConsentSaved(): boolean {
    try {
      return localStorage.getItem(CONSENT_ANALYTICS_KEY) != null
        && localStorage.getItem(CONSENT_ERROR_REPORTING_KEY) != null;
    } catch (e) {
      this.logger.info('Could not read analytics consent', e);
      return false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get sharedContextInfo(): Map<PropertyKey, ContextInfoProperty> {
    return this._sharedContextInfo$.value;
  }

  get sharedContextInfo$(): Observable<Map<PropertyKey, ContextInfoProperty>> {
    return this._sharedContextInfo$.asObservable()
      .pipe(
        // debounce repeated changes
        debounceTime(15),
      );
  }

  public setContextProperty(
    key: PropertyKey,
    value: ContextInfoProperty,
  ) {
    const current = this.sharedContextInfo.get(key);
    if (current === value) {
      // ignore no change
      return;
    }

    const copy = new Map(this.sharedContextInfo);
    copy.set(key, value);

    this._sharedContextInfo$.next(copy);
  }

  public deleteContextProperty(
    key: PropertyKey,
  ) {
    if (!this.sharedContextInfo.has(key)) {
      // ignore no change
      return;
    }

    const copy = new Map(this.sharedContextInfo);
    copy.delete(key);

    this._sharedContextInfo$.next(copy);
  }

  private refreshContextProperties(embedded: boolean): void {
    let embedding: string | undefined;

    if (embedded) {
      embedding = this.embeddedService.embedding;
      const analytics = this.embeddedService.contextInfo.get(WellKnownJustFarmingProperties.ANALYTICS);
      this.consentAnalytics = analytics === true;

      const errorReporting = this.embeddedService.contextInfo.get(WellKnownJustFarmingProperties.ERROR_REPORTING);
      this.consentErrorReporting = errorReporting === true;
    } else {
      embedding = undefined;

      this.consentAnalytics = this.savedConsentAnalytics;
      this.consentErrorReporting = this.savedConsentErrorReporting;
    }

    this.setContextProperty(WellKnownShellProperties.EMBEDDED_EMBEDDED, embedded);
    this.setContextProperty(WellKnownShellProperties.EMBEDDED_EMBEDDING, embedding);
    this.setContextProperty(WellKnownJustFarmingProperties.ANALYTICS, this.consentAnalytics);
    this.setContextProperty(WellKnownJustFarmingProperties.ERROR_REPORTING, this.consentErrorReporting);
  }
}
