import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EulaPopupComponent} from './eula-popup/eula-popup.component';
import {EulaComponent} from './eula/eula.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRippleModule} from '@angular/material/core';
import {PrivacyPolicyContentModule} from '../privacy-policy-content/privacy-policy-content.module';


@NgModule({
  declarations: [
    EulaPopupComponent,
    EulaComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatRippleModule,
    PrivacyPolicyContentModule,
  ]
})
export class EulaModule {
}
