import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {
  AuthInterceptor as OidcAuthInterceptor,
  AuthModule,
  OidcSecurityService,
  OpenIdConfiguration,
  StsConfigHttpLoader,
  StsConfigLoader
} from 'angular-auth-oidc-client';
import {LoggerModule, NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {ConfigModule} from './config/config.module';
import {ConfigService} from './config/service/config.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular-ivy';
import {filter, tap} from 'rxjs/operators';
import {map, take} from 'rxjs';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {EmbeddedService} from './embedded/embedded.service';
import {AuthInterceptor} from './auth/auth.interceptor';
import {EulaModule} from './eula/eula.module';
import {
  MatomoConsentMode,
  MatomoInitializationMode,
  MatomoInitializerService,
  NgxMatomoTrackerModule,
} from '@ngx-matomo/tracker';
import {NgxMatomoRouterModule} from '@ngx-matomo/router';
import {ContextInfoService} from './iframe-apps/context-info.service';
import {MatDialogModule} from '@angular/material/dialog';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';


registerLocaleData(localeDe, 'de-DE', localeDeExtra);


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    AppRoutingModule,
    EulaModule,
    NgbModule,
    // Config
    ConfigModule,
    // Logging
    LoggerModule.forRoot({
      level: NgxLoggerLevel.INFO, // default logging level
      enableSourceMaps: !environment.production,
    }),
    // Auth
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: (configService: ConfigService) => {
          const configObservable = configService.loaded$
            .pipe(
              take(1),
              map(config => {
                const authConfig: OpenIdConfiguration = {
                  // loaded
                  authority: config.auth.authority,
                  clientId: config.auth.clientId,
                  scope: config.auth.scope,
                  logLevel: config.auth.logLevel,
                  renewTimeBeforeTokenExpiresInSeconds: config.auth.renewTimeBeforeTokenExpiresInSeconds,
                  // INFO: Bei anpassungen auch AuthInterceptor.matchesSecureRoots() anpassen
                  secureRoutes: [config.base.baseUrl, config.shell.selfserviceBaseUrl],
                  // predefined
                  redirectUrl: window.location.origin,
                  postLogoutRedirectUri: window.location.origin,
                  responseType: 'code',
                  silentRenew: true,
                  triggerAuthorizationResultEvent: true,
                  ignoreNonceAfterRefresh: true,
                  useRefreshToken: true,
                };
                return authConfig;
              }),
            );
          return new StsConfigHttpLoader(configObservable);
        },
        deps: [ConfigService],
      },
    }),
    NgxMatomoTrackerModule.forRoot({
      mode: MatomoInitializationMode.AUTO_DEFERRED,
      requireConsent: MatomoConsentMode.TRACKING,
    }),
    NgxMatomoRouterModule,
  ],
  exports: [
    AuthModule,
  ],
  providers: [
    OidcAuthInterceptor,
    {
      // INFO: Interceptor um automatisch da OAuth Token zu setzen
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      // INFO: Initializer das Logging
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService, logger: NGXLogger) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(value => {
              logger.updateConfig({
                ...logger.getConfigSnapshot(),
                level: value.logging.level
              });
            }),
          );
      },
      deps: [ConfigService, NGXLogger],
      multi: true,
    },
    {
      // INFO: Initializer für Sentry Integration
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        contextInfoService: ContextInfoService,
        logger: NGXLogger,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(config => {
              const sentry = config.sentry;
              const info = environment.info;
              if (sentry) {
                Sentry.init({
                  enabled: sentry.enabled,
                  debug: sentry.debug,
                  environment: config.base.environment,
                  release: info.commit,
                  dsn: sentry.dsn,
                  sampleRate: sentry.sampleRate,
                  autoSessionTracking: false,
                  enableTracing: false,
                  maxBreadcrumbs: 100,
                  initialScope: {
                    extra: {
                      'info': info,
                    },
                  },
                  beforeSend: (event) => {
                    if (contextInfoService?.consentErrorReporting) {
                      return event;
                    }
                    logger.debug('Error reporting is disabled, dropping event');
                    return null;
                  },
                  beforeSendTransaction: (event) => {
                    if (contextInfoService?.consentErrorReporting) {
                      return event;
                    }
                    logger.debug('Error reporting is disabled, dropping transaction event');
                    return null;
                  },
                  beforeBreadcrumb: (breadcrumb) => {
                    if (contextInfoService?.consentErrorReporting) {
                      return breadcrumb;
                    }
                    logger.debug('Error reporting is disabled, dropping breadcrumb');
                    return null;
                  },
                });
              }
            }),
          );
      },
      deps: [
        ConfigService,
        ContextInfoService,
        NGXLogger,
      ],
      multi: true,
    },
    {
      // INFO: Angular Sentry ErrorHandler
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      // INFO: Initializer für Portal integration die Base-URL setzt
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        embeddedService: EmbeddedService,
        oidcSecurityService: OidcSecurityService,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(() => {
              const runningEmbedded = embeddedService.embedded;
              Sentry.setTag('runningEmbedded', runningEmbedded);

              if (runningEmbedded) {
                embeddedService.addAccessTokenListener(accessToken => {
                  const decoded = jwtDecode<JwtPayload>(accessToken);
                  const token = decoded as any;

                  const email = token.email;
                  const username = token.preferred_username;
                  const name = token.name;

                  Sentry.withScope(scope => {
                    let user = scope.getUser();
                    if (!user) {
                      user = {};
                    }
                    user.id = decoded.sub;
                    user.email = email;
                    user.username = username;
                    user['name'] = name;

                    scope.setUser(user);
                  });
                });
              } else {
                oidcSecurityService.getAccessToken()
                  .pipe(
                    filter(value => {
                      if (value) {
                        return true;
                      }
                      return false;
                    }),
                  )
                  .subscribe(accessToken => {
                    const decoded = jwtDecode<JwtPayload>(accessToken);
                    const token = decoded as any;

                    const email = token.email;
                    const username = token.preferred_username;
                    const name = token.name;

                    Sentry.withScope(scope => {
                      let user = scope.getUser();
                      if (!user) {
                        user = {};
                      }
                      user.id = decoded.sub;
                      user.email = email;
                      user.username = username;
                      user['name'] = name;

                      scope.setUser(user);
                    });
                  });
              }
            }),
          );
      },
      deps: [ConfigService, EmbeddedService, OidcSecurityService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        matomoInitializerService: MatomoInitializerService,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(config => {
              const matomoConfig = config.matomo;

              if (matomoConfig?.enabled
                && matomoConfig?.trackerUrl
                && matomoConfig?.siteId) {

                matomoInitializerService.initializeTracker({
                  trackerUrl: matomoConfig.trackerUrl,
                  siteId: matomoConfig.siteId,
                });

              }
            }),
          );
      },
      deps: [ConfigService, MatomoInitializerService],
      multi: true,
    },
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {
}
