import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, map, mergeMap, Observable, of, ReplaySubject, Subject, take, takeUntil} from 'rxjs';
import {
  AppConfig,
  AppFullKey,
  IntentConfig,
  IntentFullKey,
  IntentLegacyFullKey,
  IntentVersion,
  WidgetConfig,
} from '../../app-view/api/v1/types';
import {ConfigService} from './config.service';


export interface FeatureFlag {
  showDevApp: boolean;
  showHelpLink: boolean;
  skipEulaApproval: boolean;
}

export interface Eula {
  approved: boolean;
}

export interface Branding {
  key: string,
  name: string,
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  phone?: string;
  fax?: string;
  email?: string;
  website?: string;
  contact?: string;
}

export class Apps {

  constructor(
    public appConfigs: AppConfig[],
  ) {
  }

  get appConfigMap(): Map<AppFullKey, AppConfig> {
    return new Map(this.appConfigs.map(app => [app.fullKey, app]));
  }

  get appConfigNavigator(): AppConfig[] {
    return this.appConfigs.filter(app => app.navigator);
  }

  get appConfigEmbedded(): AppConfig[] {
    return this.appConfigs.filter(app => app.embedded);
  }

  get appConfigEmbeddedMap(): Map<AppFullKey, AppConfig> {
    return new Map(this.appConfigEmbedded.map(app => [app.fullKey, app]));
  }
}

export class Widgets {

  constructor(
    public widgetConfigs: WidgetConfig[],
  ) {
  }
}

export class Intents {

  readonly intentVersionConfigs = new Map<IntentFullKey, Map<IntentVersion, IntentConfig>>();

  readonly intentVersionConfigsLegacy = new Map<IntentLegacyFullKey, Map<IntentVersion, IntentConfig>>();

  constructor(
    public intentConfigs: IntentConfig[],
  ) {
    for (const intentConfig of intentConfigs) {
      let versionConfigs = this.intentVersionConfigs.get(intentConfig.fullKey);
      if (!versionConfigs) {
        versionConfigs = new Map();

        this.intentVersionConfigs.set(intentConfig.fullKey, versionConfigs);
      }
      versionConfigs.set(intentConfig.version, intentConfig);
    }
    for (const intentConfig of intentConfigs) {
      let versionConfigs = this.intentVersionConfigsLegacy.get(intentConfig.legacyFullKey);
      if (!versionConfigs) {
        versionConfigs = new Map();

        this.intentVersionConfigsLegacy.set(intentConfig.legacyFullKey, versionConfigs);
      }
      versionConfigs.set(intentConfig.version, intentConfig);
    }
  }
}

export interface IContextConfig {
  apps: AppConfig[],
  intents: IntentConfig[],
  widgets: WidgetConfig[],
  featureFlag: FeatureFlag,
  eula: Eula,
  branding?: Branding,
}

export class ContextConfig {

  constructor(
    public apps: Apps,
    public intents: Intents,
    public widgets: Widgets,
    public featureFlag: FeatureFlag,
    public eula: Eula,
    public branding?: Branding,
  ) {
  }
}


@Injectable()
export class ContextConfigService
  implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private readonly loaded = new ReplaySubject<ContextConfig>();

  private contextConfig_?: ContextConfig;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.loaded.complete();
  }

  get loaded$() {
    return this.loaded.pipe(
      takeUntil(this.unsubscribe$),
    );
  }

  get contextConfig(): ContextConfig {
    return this.contextConfig_ as ContextConfig;
  }

  get apps(): Apps {
    return this.contextConfig_?.apps as Apps;
  }

  get intents(): Intents {
    return this.contextConfig_?.intents as Intents;
  }

  get widgets(): Widgets {
    return this.contextConfig_?.widgets as Widgets;
  }

  get featureFlag(): FeatureFlag {
    return this.contextConfig_?.featureFlag as FeatureFlag;
  }

  loadConfig(
    requiredValues: Observable<any>,
  ): Observable<ContextConfig> {
    // INFO: load config only once
    if (!this.contextConfig_) {
      return forkJoin([
        this.getContextConfigs(requiredValues),
      ]).pipe(
        map(value => {
          // INFO: load config only once
          const contextConfig = value[0];
          if (!this.contextConfig_) {
            this.contextConfig_ = contextConfig;

            this.loaded.next(this.contextConfig_);
          }
          return this.contextConfig_;
        }),
      );
    }
    return of(this.contextConfig_);
  }

  private getContextConfigs(
    requiredValues: Observable<any>,
  ): Observable<ContextConfig> {
    return this.configService.loaded$
      .pipe(
        take(1),
        mergeMap(value => {
          return requiredValues.pipe(
            map(() => value),
          );
        }),
        map((value) => value.base),
        map(baseConfig => `${baseConfig.baseUrl}/api/v1/context`),
        mergeMap(url => this.httpClient.get<IContextConfig>(url)),
        map(value => {
          return new ContextConfig(
            new Apps(value.apps),
            new Intents(value.intents),
            new Widgets(value.widgets),
            value.featureFlag,
            value.eula,
            value.branding,
          );
        }),
      );
  }
}
